import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#bc0000'
        },
        secondary: {
            main: '#171717'
        }
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
    },
});

export default theme