import { Link, graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import '../../styles/navbar.scss'
import { Box, Button, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import SecurityIcon from '@mui/icons-material/Security';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface closeFunction {
    onClose: () => void;
}

export default function WhatWeDoMegaMenu({ onClose }: closeFunction) {

    const handleItemClick = () => {
        onClose(); // Call the onClose prop to close the megamenu in the parent component
    };

    return (
        <Stack
            direction="row"
            spacing={6}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100vw"}
            textAlign={"left"}
            className="whatwedo-menu"
        >
            <Box className="megamenu-column">
                <Link to="/solutions/external-attack-surface-management/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />External Attack Surface Management</MenuItem></Link>
                <Link to="/solutions/security-rating-services/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />Security Rating Services</MenuItem></Link>
                <Link to="/solutions/third-party-risk-management/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />Third Party Risk Management</MenuItem></Link>
                <Link to="/solutions/surface-deep-dark-web-intelligence/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />Surface, Deep & Dark Web Intelligence</MenuItem></Link>
                <Link to="/solutions/brand-protection-and-anti-phishing/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />Phishing & Brand Protection</MenuItem></Link>
                <Link to="/solutions/social-media-fraud-monitoring/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />Social Media Fraud Monitoring</MenuItem></Link>
                <Link to="/solutions/risk-based-hardening-guidelines/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />Risk-based Hardening</MenuItem></Link>
                <Link to="/solutions/managed-takedowns/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />Managed Takedowns</MenuItem></Link>
                <Link to="/solutions/dmarc-reports-analysis/"><MenuItem className="megamenu-item" onClick={handleItemClick}><SecurityIcon />DMARC Reports Analysis</MenuItem></Link>
            </Box>
        </Stack>
    )
}