import { Link, graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import '../../styles/navbar.scss'
import { Box, Button, ListItemText, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import MovingIcon from '@mui/icons-material/Moving';

import TrackChangesIcon from '@mui/icons-material/TrackChanges'; //to be changed

import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import { GatsbyImage, getImage } from "gatsby-plugin-image";


interface closeFunction {
    onClose: () => void;
}

export default function CompanyMegaMenu({ onClose }: closeFunction) {

    const handleItemClick = () => {
        onClose(); // Call the onClose prop to close the megamenu in the parent component
    };

    const images = useStaticQuery(
        graphql`
  query{
    award: imageSharp(fluid: {originalName: {eq: "frost1.png"}}) { # or frost.jpg
      gatsbyImageData(placeholder: BLURRED)
    }
    webinar: imageSharp(fluid: {originalName: {eq: "webinar_banner2.png"}}) { 
      gatsbyImageData(placeholder: BLURRED)
    }
    
  }
  `
    )

    const award = getImage(images.award)
    const webinar = getImage(images.webinar)

    return (
        <Stack
            direction="row"
            spacing={6}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100vw"}
            textAlign={"left"}
            className="company-menu"
        >
            <Box className="megamenu-filter">
                {award && <GatsbyImage image={award} alt="frost&sullivan" />}
                {/* {webinar && <GatsbyImage image={webinar} alt="webinar" />} */}
                {/* <Button component={Link} to="/webinars/actionable-threat-intelligence/" onClick={handleItemClick}>Register Now</Button> */}
                <Button component={Link} to="https://www.newswire.ca/news-releases/ctm360-applauded-by-frost-amp-sullivan-for-continuously-innovating-and-improving-security-products-with-its-enabling-technologies-821711630.html">View Award</Button>
            </Box>
            <Box className="megamenu-column">
                <Link to="/about-us"><MenuItem className="megamenu-item" onClick={handleItemClick}><TrackChangesIcon /><ListItemText primary="About Us" secondary="Get to know us" /></MenuItem></Link>
                <a href="https://careers.smartrecruiters.com/CTM360" target="_blank"><MenuItem className="megamenu-item" onClick={handleItemClick}><SupervisorAccountIcon /><ListItemText primary="Careers" secondary="Find a position that suits you" /></MenuItem ></a>
                <Link to="/partnerships"><MenuItem className="megamenu-item" onClick={handleItemClick}><HandshakeOutlinedIcon /><ListItemText primary="Partnerships" secondary="Mutual trust reaping exponential results" /></MenuItem ></Link>
                <Link to="/drp-digital-risk-protection-technology-stack">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <ViewQuiltOutlinedIcon />
                        <ListItemText primary="Digital Risk Protection" secondary="DRP Stack" />
                    </MenuItem>
                </Link>
                {/* <Link to="/our-strategy"><MenuItem className="megamenu-item" onClick={handleItemClick}><MovingIcon /><ListItemText primary="Our Strategy" secondary="Learn about our strategic approach" /></MenuItem></Link> */}
                <Link to="/contact-us"><MenuItem className="megamenu-item" onClick={handleItemClick}><CallIcon /><ListItemText primary="Contact Us" secondary="Get in touch" /></MenuItem ></Link>
            </Box>

        </Stack >
    )
}