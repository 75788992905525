import { Box, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import { useStaticQuery, graphql, Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import "../../styles/footer.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as React from "react";
import Copyright from "./Copyright";

export default function Footer() {
  const company = [
    { name: "About Us", url: "/about-us" },
    {
      name: "Digital Risk Protection",
      url: "/drp-digital-risk-protection-technology-stack",
    },
    { name: "Events", url: "/events" },
    { name: "Our Strategy", url: "/our-strategy/" },
    { name: "Data Sheets", url: "/data-sheets" },
    { name: "Partnerships", url: "/partnerships" },
    { name: "Careers", url: "https://careers.smartrecruiters.com/CTM360" },
    {
      name: "Contact Us",
      url: "/contact-us",
    },
    {
      name: "FAQs",
      url: "/faq",
    },
  ];

  const services = [
    {
      name: "External Attack Surface Management",
      url: "/solutions/external-attack-surface-management/",
    },
    {
      name: "Security Rating Services",
      url: "/solutions/security-rating-services/",
    },
    {
      name: "Third Party Risk Management",
      url: "/solutions/third-party-risk-management/",
    },
    {
      name: "Surface, Deep & Dark Web Monitoring",
      url: "/solutions/surface-deep-dark-web-intelligence/",
    },
    { name: "Managed Takedowns", url: "/solutions/managed-takedowns/" },
    {
      name: "Phishing & Brand Protection",
      url: "/solutions/brand-protection-and-anti-phishing/",
    },
    {
      name: "Social Media Fraud Monitoring",
      url: "/solutions/social-media-fraud-monitoring/",
    },
    {
      name: "Risk-based Hardening Guidelines",
      url: "/solutions/risk-based-hardening-guidelines/",
    },
    {
      name: "DMARC Reports Analysis",
      url: "/solutions/dmarc-reports-analysis/",
    },
  ];

  const platforms = [
    {
      name: "HackerView",
      url: "/platform/external-attack-surface-management/",
    },
    { name: "CyberBlindspot", url: "/platform/targeted-threat-intelligence/" },
    { name: "ThreatCover", url: "/platform/global-cyber-threat-intelligence/" },
    { name: "DMARC360", url: "/platform/email-intelligence-oversight" },
    {
      name: "VendorControls",
      url: "/platform/vendorcontrols/",
    },
    // { name: "WebHunt", url: "/platform/webhunt/" },
  ];

  const images = useStaticQuery(
    graphql`
      query {
        logo: imageSharp(fluid: { originalName: { eq: "whitelogo.png" } }) {
          gatsbyImageData(placeholder: BLURRED)
        }
        bg: imageSharp(fluid: { originalName: { eq: "footer.png" } }) {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    `
  );

  const logo = getImage(images.logo);
  const bg = getImage(images.bg);

  return (
    <Box className="footer">
      <Box className="footer-container" maxWidth={"90vw"} margin={"0 auto"}>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 3, sm: 2, md: 4 }}
          useFlexGap
          flexWrap="wrap"
          // alignItems={"center"}
          justifyContent={"space-around"}
          max-width={"95vw"}
        >
          <MenuList className="footer-menu">
            <Typography variant="h4">Company</Typography>
            {company.map((item, index) => (
              <Link to={item.url} target="_blank" key={index}>
                <MenuItem>{item.name}</MenuItem>
              </Link>
            ))}
          </MenuList>
          <MenuList className="footer-menu">
            <Typography variant="h4">solutions</Typography>
            {services.map((item, index) => (
              <Link to={item.url} target="_blank" key={index}>
                <MenuItem>{item.name}</MenuItem>
              </Link>
            ))}
          </MenuList>
          <MenuList className="footer-menu">
            <Typography variant="h4">platforms</Typography>
            {platforms.map((item, index) => (
              <Link to={item.url} target="_blank" key={index}>
                <MenuItem>{item.name}</MenuItem>
              </Link>
            ))}
          </MenuList>
          <MenuList className="contact">
            <Typography variant="h4">Contact Us</Typography>
            <MenuItem>
              <a href="tel:0097377360360">
                <LocalPhoneIcon /> +973 77 360 360
              </a>
            </MenuItem>
            <MenuItem>
              <a href="mailto:info@ctm360.com" target="_blank">
                <EmailIcon /> info@ctm360.com
              </a>
            </MenuItem>
            <MenuItem>
              <a href="https://goo.gl/maps/G8FiBKLtvpSJkeaw8" target="_blank">
                <LocationOnIcon />
                <Box className="address">
                  <Typography variant="body1">BH OFFICE</Typography>
                  <Typography variant="body1">
                    21st Floor, Harbour Towers <br />
                    East, Bahrain Financial Harbour <br />
                    Manama, Kingdom of Bahrain
                  </Typography>
                </Box>
              </a>
            </MenuItem>
            <MenuItem
              className="usa-contact"
              disableRipple
              style={{ cursor: "default" }}
            >
              <LocationOnIcon />
              <Box className="address">
                <Typography variant="body1">US OFFICE</Typography>
                <Typography variant="body1">
                  Inc 8 The Green STE B Dover DE <br />
                  19901 United States of America
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem>
              <a href="tel:001 (631) 540-1865">
                <LocalPhoneIcon /> +1 (631) 540-1865
              </a>
            </MenuItem>
          </MenuList>
        </Stack>
      </Box>

      <Copyright />
      <script
        dangerouslySetInnerHTML={{
          __html: `
        _linkedin_partner_id = "2295778";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
      `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt="linkedinscript"
          src="https://px.ads.linkedin.com/collect/?pid=2295778&fmt=gif"
        />
      </noscript>
    </Box>
  );
}
