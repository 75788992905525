import * as React from "react";
import { Box, SvgIcon, Typography } from "@mui/material";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "../../styles/footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GooglePlaySVG from "../../images/google-play.svg";
import AppStoreSVG from "../../images/app-store-badge-821203008f97e78939fd1be07549ad0e.svg";
import logo from "../../images/ctm360-logo-svg.svg";

export default function Copyright() {
  const images = useStaticQuery(
    graphql`
      query {
        soc: imageSharp(
          fluid: { originalName: { eq: "21972-312_SOC_NonCPA.png" } }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    `
  );

  const soc = getImage(images.soc);

  return (
    <Box className="copyright">
      <Box className="badges">
        {soc && <GatsbyImage image={soc} alt="SOC2" />}
        <iframe
          title="ctmCertificates"
          src="https://registry.blockmarktech.com/certificates/cc805301-75e8-4087-a859-f213587e49f7/widget/?tooltip_position=bottom_right&theme=transparent&hover=t"
          style={{
            border: "none",
            height: "100px",
            width: "100px",
          }}
        ></iframe>
      </Box>

      <Box className="copyright-text">
        <Typography variant="body1">
          Copyright © {new Date().getFullYear()} All rights reserved |{" "}
          <Link to="/terms-of-use">TERMS OF USE</Link> |{" "}
          <Link to="/privacy-policy">PRIVACY POLICY</Link>
        </Typography>
        <Typography variant="body1">
          Powered by{" "}
          <a href="https://www.edxlabs.com/" target="_blank">
            EDX Labs
          </a>
          <img
            src={logo}
            style={{ opacity: 0, width: 0, height: 0 }}
            alt="logo"
          />
        </Typography>
      </Box>
      <Box className="social-icons">
        <a
          href="https://www.facebook.com/ctm360/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/ctm360/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://twitter.com/teamCTM360"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SvgIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 520 520"
              strokeWidth={1.5}
              stroke="currentColor"
              className="css-vubbuv"
            >
              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
            </svg>
          </SvgIcon>
          {/* <TwitterIcon /> */}
        </a>
        <a
          href="https://www.linkedin.com/company/ctm360"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </a>
        <a
          href="https://www.youtube.com/@CTM360"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon />
        </a>
        <Box className="mobile-apps">
          <a
            href="https://apps.apple.com/us/app/ctm360-app/id6499311713"
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-app"
          >
            <img src={AppStoreSVG} alt="app-download" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.ctm360.app"
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-app"
          >
            <img src={GooglePlaySVG} alt="app-download" />
          </a>
        </Box>
      </Box>
    </Box>
  );
}
