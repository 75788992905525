import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import './src/styles/global.scss'
import { ThemeProvider } from "@mui/material/styles"
import theme from './src/theme/theme'
import Header from './src/components/Navbar/Header'
import Footer from './src/components/Footer/Footer'
import Loading from './src/components/Loading/Loading';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const onInitialClientRender = () => {
  setTimeout(function () {
    const loaderElement = document.getElementById("___loader");
    if (loaderElement) {
      loaderElement.style.display = "none";
    }
  }, 1000);
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element, props
}) => {

  return (
    <div>
      <Header />
      {element}
      <Footer />
    </div>
  )
}

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element
}) => {
  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider
          reCaptchaKey={"6Lf9ifQmAAAAAO_H-7wu6fGJl4wMOxypzBvYVhVw"}
          scriptProps={{ async: true }}
      >
        {element}
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  )
} 
