import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import "../../styles/navbar.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Collapse, Menu } from "@mui/material";
import CompanyMegaMenu from "./CompanyMegamenu";
import PlatformMegaMenu from "./PlatformMegamenu";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import WhatWeDoMegaMenu from "./WhatWeDoMegamenu";
import { menuData } from "@/data/mobileMenu";
import ResourcesMegaMenu from "./ResourcesMegamenu";
import Recognition from "./Recognition";
import { AnimatePresence, motion } from "framer-motion";

interface MenuItem {
  id: string;
  title: string;
  secondary?: string;
  link?: string;
  external?: boolean;
  subMenu?: MenuItem[];
}

export const Header: React.FC = () => {
  const images = useStaticQuery(
    graphql`
      query {
        logo: imageSharp(fluid: { originalName: { eq: "logo.png" } }) {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    `
  );
  const logoImage = getImage(images.logo);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [navHome, setNavHome] = React.useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setOpen({
      company: false,
      solutions: false,
      platforms: false,
      resources: false,
    });
  };

  const controlNavbar = () => {
    if (window.scrollY > 0) {
      setNavHome(false);
    } else {
      setNavHome(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  //MegaMenu Manager

  const [openMenu, setOpenMenu] = React.useState<string>("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [menuHover, setMenuHover] = React.useState<{
    [key: string]: Boolean;
  }>({
    platforms: false,
    solutions: false,
    company: false,
    resources: false,
  });

  function handleButtonMouseEnter(
    event: React.MouseEvent<HTMLButtonElement>,
    menu: string
  ) {
    // When mouse enters, just open the menu
    setOpenMenu(menu);
    setAnchorEl(event.currentTarget);
  }

  function handleButtonMouseLeave(menu: string) {
    // When mouse leaves, set a timer to close the menu
    setOpenMenu("");
    setTimeout(() => {
      if (!menuHover[menu]) {
        handleCloseMenuHover(menu);
      }
    }, 200);
  }

  function handleMenuMouseEnter(menu: string) {
    // When mouse enters the menu, keep it open
    setOpenMenu(menu);
    setMenuHover((prevState) => ({
      ...prevState,
      [menu]: true,
    }));
  }

  function handleMenuMouseLeave(menu: string) {
    // When mouse leaves the menu, close it
    setOpenMenu("");
    setMenuHover((prevState) => ({
      ...prevState,
      [menu]: false,
    }));
  }

  const handleCloseMenu = (menu: string) => {
    setOpenMenu("");
    setAnchorEl(null);
    setMenuHover((prevState) => ({
      ...prevState,
      [menu]: false,
    }));
  };

  function handleCloseMenuHover(menu: string) {
    setMenuHover((prevState) => ({
      ...prevState,
      [menu]: false,
    }));
    setTimeout(() => {
      if (!menuHover) {
        handleCloseMenu(menu);
      }
    }, 50);
  }

  // Mobile Navigation Panel Handlers

  const [open, setOpen] = React.useState<{
    [key: string]: boolean;
  }>({
    company: false,
    solutions: false,
    platforms: false,
    resources: false,
  });

  const handlePhoneClick = (nav: string) => {
    setOpen((prevState) => ({
      ...prevState,
      [nav]: !prevState[nav],
    }));
  };

  const drawerWidth = 280;

  const renderSubMenu = (subMenu: MenuItem[]) => {
    return (
      <List component="div" disablePadding>
        {subMenu.map((subMenuItem) => (
          <ListItemButton
            key={subMenuItem.id}
            onClick={handleDrawerToggle}
            sx={{ pl: 4 }}
            component={subMenuItem.external ? "a" : Link}
            href={subMenuItem.external ? subMenuItem.link : undefined}
            to={!subMenuItem.external ? subMenuItem.link : undefined}
            target={subMenuItem.external ? "_blank" : undefined}
          >
            <ListItemText
              primary={subMenuItem.title}
              secondary={subMenuItem.secondary}
            />
          </ListItemButton>
        ))}
      </List>
    );
  };

  const renderMenuItems = (menuItems: MenuItem[]) => {
    return (
      <List className="nav-phone-list">
        {menuItems.map((menuItem) => (
          <React.Fragment key={menuItem.id}>
            <ListItem disablePadding>
              {!menuItem.subMenu ? (
                <Link to={menuItem.link || ""} style={{ width: "100%" }}>
                  <ListItemButton
                    sx={{ textAlign: "left" }}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemText
                      className="nav-phone-listitem"
                      primary={menuItem.title}
                    />
                  </ListItemButton>
                </Link>
              ) : (
                <ListItemButton
                  onClick={() => handlePhoneClick(menuItem.id)}
                  sx={{ textAlign: "left" }}
                >
                  <ListItemText
                    className="nav-phone-listitem"
                    primary={menuItem.title}
                  />
                  <React.Fragment>
                    {open[menuItem.id] ? (
                      <ExpandLess className="menu-expand" />
                    ) : (
                      <ExpandMore className="menu-expand" />
                    )}
                  </React.Fragment>
                </ListItemButton>
              )}
            </ListItem>
            <Collapse in={open[menuItem.id]} timeout="auto" unmountOnExit>
              {menuItem.subMenu && renderSubMenu(menuItem.subMenu)}
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    );
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Link to="/" className="drawer-logo" onClick={handleDrawerToggle}>
        {logoImage && (
          <GatsbyImage image={logoImage} alt="logo" loading="eager" />
        )}
      </Link>
      <Divider />
      {renderMenuItems(menuData)}
    </Box>
  );

  return (
    <Box>
      <AnimatePresence>
        {navHome && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            viewport={{ once: true }}
            transition={{ duration: 0.3, delay: 0 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 10 },
            }}
          >
            <Recognition />
          </motion.div>
        )}
      </AnimatePresence>
      <CssBaseline />

      <AppBar
        component="nav"
        className={navHome ? "navigation homeNav" : "navigation"}
      >
        <Toolbar className="nav-container">
          {/* LOGO SECTION */}
          <Box className="brand">
            <Link to="/">
              {logoImage && <GatsbyImage image={logoImage} alt="logo" />}
            </Link>
          </Box>

          {/* Navigation */}
          <Box
            sx={{ display: { xs: "none", sm: "block" } }}
            className="nav-list"
          >
            <Button
              // onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickMenu(e, "platforms")}
              onMouseOver={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleButtonMouseEnter(e, "platforms")
              }
              onMouseLeave={() => handleButtonMouseLeave("platforms")}
              aria-owns={anchorEl ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
            >
              Platforms{" "}
              {openMenu === "platforms" ? (
                <ExpandLess className="menu-expand" />
              ) : (
                <ExpandMore className="menu-expand" />
              )}
            </Button>
            <Menu
              id="fade-menu"
              open={openMenu === "platforms"}
              anchorEl={anchorEl}
              // open={openPlatforms}
              onClose={() => handleCloseMenu("platforms")}
              MenuListProps={{
                onMouseEnter: () => handleMenuMouseEnter("platforms"),
                onMouseLeave: () => handleMenuMouseLeave("platforms"),
                style: { pointerEvents: "auto" },
              }}
              className="megamenu"
              sx={{
                maxWidth: "100vw",
              }}
              // disableRestoreFocus
            >
              <PlatformMegaMenu onClose={() => handleCloseMenu("platforms")} />
            </Menu>

            <Button
              // onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickMenu(e, "solutions")}
              onMouseOver={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleButtonMouseEnter(e, "solutions")
              }
              onMouseLeave={() => handleButtonMouseLeave("solutions")}
              aria-owns={anchorEl ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
            >
              Solutions{" "}
              {openMenu === "solutions" ? (
                <ExpandLess className="menu-expand" />
              ) : (
                <ExpandMore className="menu-expand" />
              )}
            </Button>
            <Menu
              id="fade-menu"
              open={openMenu === "solutions"}
              // open={openSolutions}
              anchorEl={anchorEl}
              onClose={() => handleCloseMenu("solutions")}
              MenuListProps={{
                onMouseEnter: () => handleMenuMouseEnter("solutions"),
                onMouseLeave: () => handleMenuMouseLeave("solutions"),
                style: { pointerEvents: "revert-layer" },
              }}
              className="megamenu"
              sx={{
                maxWidth: "100vw",
              }}
              // disableRestoreFocus
              // disableAutoFocus
            >
              <WhatWeDoMegaMenu onClose={() => handleCloseMenu("solutions")} />
            </Menu>

            <Link to="/pricing">
              <Button>Pricing</Button>
            </Link>

            <Button
              // onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickMenu(e, "company")}
              onMouseOver={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleButtonMouseEnter(e, "company")
              }
              onMouseLeave={() => handleButtonMouseLeave("company")}
              aria-owns={anchorEl ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
            >
              Company{" "}
              {openMenu === "company" ? (
                <ExpandLess className="menu-expand" />
              ) : (
                <ExpandMore className="menu-expand" />
              )}
            </Button>
            <Menu
              id="fade-menu"
              open={openMenu === "company"}
              // open={openCompany}
              anchorEl={anchorEl}
              onClose={() => handleCloseMenu("company")}
              MenuListProps={{
                onMouseEnter: () => handleMenuMouseEnter("company"),
                onMouseLeave: () => handleMenuMouseLeave("company"),
                style: { pointerEvents: "revert-layer" },
              }}
              className="megamenu"
              sx={{
                maxWidth: "100vw",
              }}
              // disableRestoreFocus
              // disableAutoFocus
            >
              <CompanyMegaMenu onClose={() => handleCloseMenu("company")} />
            </Menu>

            <Button
              // onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClickMenu(e, "resources")}
              onMouseOver={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleButtonMouseEnter(e, "resources")
              }
              onMouseLeave={() => handleButtonMouseLeave("resources")}
              aria-owns={anchorEl ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
            >
              Resources{" "}
              {openMenu === "resources" ? (
                <ExpandLess className="menu-expand" />
              ) : (
                <ExpandMore className="menu-expand" />
              )}
            </Button>
            <Menu
              id="fade-menu"
              open={openMenu === "resources"}
              // open={openResources}
              anchorEl={anchorEl}
              onClose={() => handleCloseMenu("resources")}
              MenuListProps={{
                onMouseEnter: () => handleMenuMouseEnter("resources"),
                onMouseLeave: () => handleMenuMouseLeave("resources"),
                style: { pointerEvents: "revert-layer" },
              }}
              className="megamenu"
              sx={{
                maxWidth: "100vw",
                top: "65px",
              }}
              // disableRestoreFocus
              // disableAutoFocus
            >
              <ResourcesMegaMenu onClose={() => handleCloseMenu("resources")} />
            </Menu>
          </Box>

          {/* Free Access Button */}
          <Link to="/free-access">
            <Button type="button" className="navbar-btn btn">
              Free Access
            </Button>
          </Link>

          {/* Mobile Navigation */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
            className="hamburger-btn"
          >
            <MenuIcon className="hamburger-icon" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile navigation panel */}
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
