import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Alert, Box, Button, Grid, TextField, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";

interface ModalProps {
  data: {
    title: string;
    url: string;
  };
  handleClose: () => void;
  handleSuccess: () => void;
}

export default function DatasheetsForm({
  data,
  handleClose,
  handleSuccess,
}: ModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [fullNameError, setFullNameError] = useState("");
  const [businessEmail, setBusinessEmail] = useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [contact, setContact] = useState("");
  const [numberError, setNumberError] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  function convertToSnakeCase(str: string) {
    // Remove spaces from the string
    const stringWithoutSpaces = str.replace(/\s+/g, "");

    // Convert to lowercase and replace spaces with underscores
    const snakeCaseString = stringWithoutSpaces
      .toLowerCase()
      .replace(/\s+/g, "_");

    return snakeCaseString;
  }

  const handleContact = (phone: string) => {
    setContact(phone);
    setNumberError("");
  };

  const validateForm = () => {
    setFullNameError("");
    setEmailError("");
    setNumberError("");

    let isValid = true;

    // Validate full name
    if (fullName.trim() === "" || /\d/.test(fullName)) {
      setFullNameError("Full Name cannot be empty or contain numbers");
      isValid = false;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(businessEmail)) {
      setEmailError("Invalid Business Email");
      isValid = false;
    }

    // Validate contact number
    if (contact.trim() === "") {
      setNumberError("Contact Number is required");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!executeRecaptcha) return;

    if (!validateForm()) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);

    setLoading(true);
    const token = await executeRecaptcha(
      `datasheetForm${convertToSnakeCase(fullName)}`
    );
    const jsonobj = {
      title: data.title + " Download",
      fullName: fullName,
      email: businessEmail,
      mobileNo: contact,
      fromEmail: "info@ctm360.com",
      captcha_token: token,
    };

    fetch("https://ctm-website-form.ctm360.com/api/form/datasheet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        dataType: "json",
      },
      body: JSON.stringify(jsonobj),
    })
      .then(() => {
        setLoading(false);
        saveAs(data.url, data.title);
        setBusinessEmail("");
        setEmailError("");
        setContact("");
        setNumberError("");
        setFullName("");
        setFullNameError("");
        handleSuccess();
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
      });
  };

  return (
    <Box className="formwrap__outer">
      <Box component="form" onSubmit={handleSubmit} margin={0}>
        <Grid container className="datasheets-form">
          <Grid item xs={12}>
            <TextField
              className="datasheets-form-field"
              id="fullName"
              name="fullName"
              label="Full Name"
              variant="outlined"
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
              error={!!fullNameError}
              helperText={fullNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="datasheets-form-field"
              id="businessEmail"
              name="businessEmail"
              label="Business Email"
              value={businessEmail}
              variant="outlined"
              error={!!emailError}
              helperText={emailError}
              onChange={(e) => setBusinessEmail(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className="datasheets-form-phone"
            sx={{
              ".react-tel-input": {
                ".form-control": {
                  borderColor: !!numberError
                    ? "#d32f2f"
                    : "rgba(0, 0, 0, 0.23)",
                },
              },
            }}
          >
            <PhoneInput
              specialLabel="Contact Number*"
              country="bh"
              inputProps={{
                name: "contactNumber",
                required: true,
                autoFocus: true,
              }}
              value={contact}
              onChange={(value) => handleContact(value)}
            />
            {numberError && (
              <Typography style={{ color: "red", margin: "3px 14px 0" }}>
                {numberError}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Button
          className="btn datasheet-item-btn"
          type="submit"
          disabled={loading}
          startIcon={<CloudDownloadIcon />}
        >
          {loading ? "Loading..." : "Download"}
        </Button>
      </Box>
    </Box>
  );
}
