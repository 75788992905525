// data.tsx

export const menuData = [
  {
    id: "platforms",
    title: "Platforms",
    subMenu: [
      {
        id: "external-attack-surface-management",
        title: "HackerView",
        secondary: "External Attack Surface Management",
        link: "/platform/external-attack-surface-management/",
      },
      {
        id: "targeted-threat-intelligence",
        title: "CyberBlindspot",
        secondary: "Targeted Threat Intelligence",
        link: "/platform/targeted-threat-intelligence/",
      },
      {
        id: "global-cyber-threat-intelligence",
        title: "ThreatCover",
        secondary: "Global Cyber Threat Intelligence",
        link: "/platform/global-cyber-threat-intelligence/",
      },
      {
        id: "email-intelligence-oversight",
        title: "DMARC360",
        secondary: "Email Security Meets Intelligence",
        link: "/platform/email-intelligence-oversight/",
      },
      // {
      //   id: "webhunt",
      //   title: "WebHunt",
      //   secondary: "Research & Analysis Made Easy",
      //   link: "/platform/webhunt/",
      // },
      {
        id: "vendorcontrols",
        title: "VendorControls",
        secondary: "Security QUestionnaire",
        link: "/platform/vendorcontrols/",
      },
    ],
  },
  {
    id: "solutions",
    title: "Solutions",
    subMenu: [
      {
        id: "attack-surface-management",
        title: "External Attack Surface Management",
        link: "/solutions/external-attack-surface-management/",
      },
      {
        id: "security-ratings",
        title: "Security Rating Services",
        link: "/solutions/security-rating-services/",
      },
      {
        id: "third-party-risk",
        title: "Third Party Risk Management",
        link: "/solutions/third-party-risk-management/",
      },
      {
        id: "managed-takedowns",
        title: "Managed Takedowns",
        link: "/solutions/managed-takedowns/",
      },
      {
        id: "deep-dark-web-monitoring",
        title: "Surface, Deep & Dark Web Intelligence",
        link: "/solutions/surface-deep-dark-web-intelligence/",
      },
      {
        id: "phishing-brand-protection",
        title: "Phishing & Brand Protection",
        link: "/solutions/brand-protection-and-anti-phishing/",
      },
      {
        id: "social-media-protection",
        title: "Social Media Fraud Monitoring",
        link: "/solutions/social-media-fraud-monitoring/",
      },
      {
        id: "dmarc-reports-analysis",
        title: "DMARC Reports Analysis",
        link: "/solutions/dmarc-reports-analysis/",
      },
      {
        id: "risk-based-hardening-guidelines",
        title: "Risk-based Hardening Guidelines",
        link: "/solutions/risk-based-hardening-guidelines/",
      },
    ],
  },
  {
    id: "company",
    title: "Company",
    subMenu: [
      { id: "about-us", title: "About Us", link: "/about-us" },
      { id: "our-strategy", title: "Our Strategy", link: "/our-strategy" },
      {
        id: "digital-risk-protection-technology-stack",
        title: "Digital Risk Protection Stack",
        link: "/drp-digital-risk-protection-technology-stack/",
      },
      { id: "partnerships", title: "Partnerships", link: "/partnerships" },
      {
        id: "careers",
        title: "Careers",
        link: "https://careers.smartrecruiters.com/CTM360",
        external: true,
      },
      { id: "contact-us", title: "Contact Us", link: "/contact-us" },
    ],
  },
  {
    id: "pricing",
    title: "Pricing",
    link: "/pricing",
  },
  {
    id: "free-access",
    title: "Free Access",
    link: "/free-access",
  },

  {
    id: "resources",
    title: "Resources",
    subMenu: [
      { id: "blogs", title: "Blogs", link: "/blogs" },
      { id: "media", title: "Media", link: "/media" },
      { id: "events", title: "Events", link: "/events" },
      { id: "data-sheets", title: "Data Sheets", link: "/data-sheets" },
      { id: "glossary", title: "Glossary", link: "/glossary" },
      { id: "faq", title: "FAQs", link: "/faq" },
    ],
  },
];
