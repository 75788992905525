import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import "../../styles/navbar.scss";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import HubIcon from "@mui/icons-material/Hub";
interface closeFunction {
  onClose: () => void;
}

export default function PlatformMegaMenu({ onClose }: closeFunction) {
  const handleItemClick = () => {
    onClose(); // Call the onClose prop to close the megamenu in the parent component
  };

  const images = useStaticQuery(
    graphql`
      query {
        allPlatforms: imageSharp(
          fluid: { originalName: { eq: "allPlatforms.webp" } }
        ) {
          # or frost.jpg
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    `
  );

  const allPlatforms = getImage(images.allPlatforms);

  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100vw"}
      className="platforms-menu"
    >
      <Box className="megamenu-filter">
        {/* <Typography variant="h3">Our Comprehensive Platforms</Typography> */}
        {allPlatforms && (
          <GatsbyImage image={allPlatforms} alt="allPlatforms" />
        )}
        <Button
          component={Link}
          onClick={handleItemClick}
          to="/contact-us"
          state={{
            selection: `Platform Demo`,
          }}
        >
          Request a demo
        </Button>
      </Box>
      <Box className="megamenu-column">
        <Link
          to="/platform/external-attack-surface-management/"
          // onMouseOver={() => handlePlatformHover("hv")}
        >
          <MenuItem className="megamenu-item" onClick={handleItemClick}>
            <ListItemIcon>
              <RemoveRedEyeIcon />
            </ListItemIcon>
            <ListItemText
              primary="HackerView"
              secondary="External Attack Surface Management"
            />
          </MenuItem>
        </Link>
        <Link
          to="/platform/targeted-threat-intelligence/"
          // onMouseOver={() => handlePlatformHover("cbs")}
        >
          <MenuItem className="megamenu-item" onClick={handleItemClick}>
            <ListItemIcon>
              <CrisisAlertIcon />
            </ListItemIcon>
            <ListItemText
              primary="CyberBlindspot"
              secondary="Targeted Threat Intelligence"
            />
          </MenuItem>
        </Link>
        <Link
          to="/platform/global-cyber-threat-intelligence/"
          // onMouseOver={() => handlePlatformHover("tc")}
        >
          <MenuItem className="megamenu-item" onClick={handleItemClick}>
            <ListItemIcon>
              <HubIcon />
            </ListItemIcon>
            <ListItemText
              primary="ThreatCover"
              secondary="CTI Risk-based Hardening"
            />
          </MenuItem>
        </Link>
        <Link
          to="/platform/email-intelligence-oversight/"
          // onMouseOver={() => handlePlatformHover("dmarc")}
        >
          <MenuItem className="megamenu-item" onClick={handleItemClick}>
            <ListItemIcon>
              <FingerprintIcon />
            </ListItemIcon>
            <ListItemText
              primary="DMARC360"
              secondary="Email Security Meets Intelligence"
            />
          </MenuItem>
        </Link>
        <Link
          to="/platform/vendorcontrols/"
          // onMouseOver={() => handlePlatformHover("dmarc")}
        >
          <MenuItem className="megamenu-item" onClick={handleItemClick}>
            <ListItemIcon>
              <Diversity3Icon />
            </ListItemIcon>
            <ListItemText
              primary="VendorControls"
              secondary="Security Questionnaires"
            />
          </MenuItem>
        </Link>
        {/* <Link
          to="/platform/webhunt/"
        // onMouseOver={() => handlePlatformHover("dmarc")}
        >
          <MenuItem className="megamenu-item" onClick={handleItemClick}>
            <ListItemIcon>
              <TroubleshootIcon />
            </ListItemIcon>
            <ListItemText primary="WebHunt" secondary="Research & Analysis" />
          </MenuItem>
        </Link> */}
      </Box>
    </Stack>
  );
}
