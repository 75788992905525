import React from 'react'
import { Box, Typography } from '@mui/material'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';



function RecentEvents() {

  const data = useStaticQuery(
    graphql`
  query{
    
    events: allMdx(
      filter: {parent: {internal: {description: {regex: "/content/events/"}}}}
      sort: {frontmatter: {eventDate: DESC}}
      ) {
      nodes {
      frontmatter {
        gallery {
          image {
            childrenImageSharp {
              gatsbyImageData (placeholder: BLURRED)
            }
          }
        }
        eventDate( formatString: "MMMM YYYY")
        title
      }
    }
    }
    }

  `
  )

  const event: any[] = data.events.nodes.slice(0, 1);
  return (
    <Box className="blog-container recent">
      {event.map((event) => (
        <Box key={event.frontmatter.id} className="blog-post">
          <Link key={event.frontmatter.id} to={'/events'}>
            <GatsbyImage image={getImage(event.frontmatter.gallery[0].image.childrenImageSharp[0].gatsbyImageData)} alt={event.frontmatter.title} />
            <Box className="blog-body">
              <Typography variant="body1" className="blog-date">{event.frontmatter.eventDate}</Typography>
              <Typography variant="h2" className="blog-title">{event.frontmatter.title}</Typography>
            </Box>
          </Link>
        </Box>
      ))}
    </Box>
  )
}

export default RecentEvents

