import { Link, graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import '../../styles/navbar.scss'
import { Box, Button, ListItemText, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HandshakeIcon from '@mui/icons-material/Handshake';
import MovingIcon from '@mui/icons-material/Moving';

import TrackChangesIcon from '@mui/icons-material/TrackChanges'; //to be changed

import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import RecentBlogs from "../Blogs/RecentBlogs";
import RecentEvents from "../Events/Recent";

interface closeFunction {
    onClose: () => void;
}

export default function ResourcesMegaMenu({ onClose }: closeFunction) {

    const handleItemClick = () => {
        onClose(); // Call the onClose prop to close the megamenu in the parent component
    };

    return (
        <Stack
            direction="row"
            spacing={6}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100vw"}
            textAlign={"left"}
            className="resources-menu"
        >
            <Box className="megamenu-filter">
                <Typography variant="h3"> Recent Event </Typography>
                <RecentEvents />
            </Box>
            <Box className="megamenu-column">
                {/* <Link to="/drp-digital-risk-protection-technology-stack">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <ViewQuiltOutlinedIcon />
                        <ListItemText primary="Digital Risk Protection" secondary="DRP Stack" />
                    </MenuItem>
                </Link> */}
                <Link to="/blogs">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <FeedOutlinedIcon />
                        <ListItemText primary="Blogs" secondary="Get insights from our experts" />
                    </MenuItem>
                </Link>
                <Link to="/media">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <PermMediaOutlinedIcon />
                        <ListItemText primary="Media" secondary="Explore our media presence" />
                    </MenuItem>
                </Link>
                <Link to="/events">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <EventAvailableOutlinedIcon />
                        <ListItemText primary="Events" secondary="View footage of our past events" />
                    </MenuItem>
                </Link>
                <Link to="/data-sheets">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <ArticleOutlinedIcon />
                        <ListItemText primary="Data Sheets" secondary="Detailed information at your fingertips" />
                    </MenuItem>
                </Link>
                <Link to="/glossary">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <BookOutlinedIcon />
                        <ListItemText primary="Glossary" secondary="Clarify your cybersecurity vocabulary" />
                    </MenuItem>
                </Link>
                {/* <Link to="/faq">
                    <MenuItem className="megamenu-item" onClick={handleItemClick}>
                        <LiveHelpOutlinedIcon />
                        <ListItemText primary="FAQs" secondary="Quick answers to your questions" />
                    </MenuItem>
                </Link> */}
            </Box>


        </Stack >
    )
}