import * as React from "react";
import DatasheetsForm from "../Forms/DatasheetsForm";
import { Box, Typography } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
interface ModalProps {
  data: {
    title: string;
    url: string;
  };
  handleClose: () => void;
  handleSuccess: () => void;
}

const AwardWriteup = React.forwardRef<HTMLDivElement, ModalProps>(
  ({ data, handleClose, handleSuccess }, ref) => {
    const handleCloseModal = () => {
      handleClose();
    };
    const handleSuccessForm = () => {
      handleSuccess();
    };

    return (
      <Box sx={style} ref={ref}>
        <Box className="datasheet-modal-wrapper">
          <Box className="row-eq-height">
            <Box className="popup___desc">
              <Box>
                <Typography variant="body1">Award Writeup</Typography>
                <Typography variant="h5" gutterBottom>
                  {data.title}
                </Typography>
              </Box>
            </Box>
            <Box className="popup-form">
              {/* <GoogleReCaptchaProvider
                            reCaptchaKey={"6LcQwPcmAAAAAITZ1g3NE4SByc3LIYWyKv6RNh3Q"}
                            scriptProps={{ async: true }}
                        > */}
              <Typography variant="body1">Fill the form to download</Typography>
              <DatasheetsForm
                data={data}
                handleClose={() => handleCloseModal}
                handleSuccess={handleSuccessForm}
              />
              {/* </GoogleReCaptchaProvider> */}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default AwardWriteup;
